<template>
  <div class="hello">
    <h1>Welcome to Your Portfolio</h1>
    <div class="card-wrapper">
      <div class="card" v-for="value in stonks" :key="value.id">
        <h3 class="stonk-name">{{ value.name }}
          <br>
          ({{ value.ticker }})</h3>
        Price: ${{ value.price }}
        <br>
        Shares: {{ value.shares }}
        <br>
        <div v-if="value.worth > 0">
          Worth: ${{ Math.round(value.worth * 100) / 100 }}
        </div>
        <div v-else>
          Worth: N/A
        </div>
        Invested: ${{ value.invested }}
        <h3 v-if="value.worth - value.invested > 0" class="profit">
          Profit
          <br>
          ${{ Math.round(value.worth - value.invested) }}
        </h3>
        <h3 v-else-if="value.invested - value.worth === 0" class="even">Break Even</h3>
        <h3 v-else class="loss">
          Loss
          <br>
          ${{ Math.round(value.invested - value.worth) }}
        </h3>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Stonks',
  props: {
    
  },
  mounted() {
    this.getStocks()
    window.setTimeout(() => {
      //this.stonks.doge.worth = this.stonks.doge.price * this.stonks.doge.shares
      //this.stonks.ltc.worth = this.stonks.ltc.price * this.stonks.ltc.shares
      //this.stonks.custdt.worth = this.stonks.custdt.price * this.stonks.custdt.shares
      this.stonks.orly.worth = this.stonks.orly.price * this.stonks.orly.shares
      this.stonks.googl.worth = this.stonks.googl.price * this.stonks.googl.shares
      this.stonks.coin.worth = this.stonks.coin.price * this.stonks.coin.shares
    }, 1000)
  },
  data() {
    return {
      stonks: {
        // doge: {
        //   name: 'Dogecoin',
        //   ticker: 'doge',
        //   price: '',
        //   shares: .01,
        //   worth: '',
        //   invested: 1.21,
        //   profit: ''
        // },
        // ltc: {
        //   name: 'Litecoin',
        //   ticker: 'ltc',
        //   price: '',
        //   shares: .0775,
        //   worth: '',
        //   invested: 10.00,
        //   profit: ''
        // },
        // custdt: {
        //   name: 'Compound Tether',
        //   ticker: 'custdt',
        //   price: '',
        //   shares: .02106,
        //   worth: '',
        //   invested: 0,
        //   profit: ''
        // },
        orly: {
          name: 'O\'Reilly',
          ticker: 'orly',
          price: '',
          shares: 1.258,
          worth: '',
          invested: 604.89,
          profit: ''
        },
        googl: {
          name: 'Google',
          ticker: 'googl',
          price: '',
          shares: 1.77924,
          worth: '',
          invested: 247.49,
          profit: ''
        },
        coin: {
          name: 'Coinbase',
          ticker: 'coin',
          price: '',
          shares: 2,
          worth: '',
          invested: 396.49,
          profit: ''
        }
      }
    }
  },
  methods: {
    getStocks() {
      // axios
      //   .get('https://api.kraken.com/0/public/Ticker?pair=DOGEUSD')
      //   .then(response => {
      //       let output = response.data.result.XDGUSD.a[0]
      //       this.stonks.doge.price = Math.round(output * 100000) / 100000
      //   })
      //   .catch(error => {
      //     console.error(error)
      //   })
      // axios
      //   .get('https://api.kraken.com/0/public/Ticker?pair=LTCUSD')
      //   .then(response => {
      //       let output = response.data.result.XLTCZUSD.a[0]
      //       this.stonks.ltc.price = Math.round(output * 100000) / 100000
      //   })
      //   .catch(error => {
      //     console.error(error)
      //   })
      // axios
      //   .get('https://api.cryptonator.com/api/ticker/custdt-usd/')
      //   .then(response => {
      //       let output = response.data.ticker.price
      //       this.stonks.custdt.price = Math.round(output * 100000) / 100000
      //   })
      //   .catch(error => {
      //     console.error(error)
      //   })
      axios
        .get('https://finnhub.io/api/v1/quote?symbol=ORLY&token=c2atjfiad3ibqimon0fg')
        .then(response => {
            this.stonks.orly.price = response.data.c
        })
        .catch(error => {
          console.error(error)
        })
      axios
        .get('https://finnhub.io/api/v1/quote?symbol=GOOGL&token=c2atjfiad3ibqimon0fg')
        .then(response => {
            this.stonks.googl.price = response.data.c
        })
        .catch(error => {
          console.error(error)
        })
      axios
        .get('https://finnhub.io/api/v1/quote?symbol=COIN&token=c2atjfiad3ibqimon0fg')
        .then(response => {
            this.stonks.coin.price = response.data.c
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>
<style>
.card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  background-color: #2c3e50;
  color: white;
  border-radius: 25px;
  box-shadow: 5px 8px gray;
  width: 250px;
  height: 250px;
  padding: 20px;
  margin: 5px;
  align-self: center;
  text-align: center;
}
.card-lt {
  color: black;
  width: 250px;
  padding: 20px;
  margin: 5px;
  align-self: center;
  text-align: center;
}
.stonk-name {
  font-weight: bold;
  text-transform: uppercase;
}
.profit {
  color: #42c217;
  font-weight: bold;
}
.loss {
  color: red;
  font-weight: bold;
}
.even {
  color: #ccab25;
  font-weight: bold;
}
</style>
